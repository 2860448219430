/* # id, . class icin  */

:root {
  /* TODO: Combine fonts, e.g., one for headers and one for text */
  --main-font: 'Noto Sans', sans-serif;
  /* --main-font: Ubuntu, sans-serif; */
}


/* //////////////////////////////// */
/* //////////  Base Styles  ///////*/
/* /////////////////////////////// */

html, body {
  font-family: var(--main-font);
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-stretch: 150%;
  /* display: flex; */
  /* overflow: auto; */
 }

 main {
  height: calc(100% - 190px);
  overflow: hidden;
  padding-top: 55px;
 }


/* container for icons in the first step: */
#introContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


#introduction, #information, #pre_questionnaire {
  max-width: 650px;
  /* margin: auto; */
  /* display: flex; */
;
 }


/* ///////// */
/* interface */
/* ///////// */

#top {
  margin-left:  13%;
  margin-right: 13%;
}

#stepsContainer{
  margin-left:  13%;
  margin-right: 13%;
  max-height: 100%;
  overflow-y: auto; /* Add vertical scrollbar */
  padding: 10px; /* Optional: Add padding to the container */
}

.interfaceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left:  10%;
  margin-right: 10%; */
}

.box {
  width: 200px;
  height: 200px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.playButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}


.icon {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  margin-bottom: 10px;
}


button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}


button:disabled {
  background-color: #ced4da; /* Change to desired color when disabled */
  cursor: not-allowed;
}


.rate-tap-btn {
  background-color: #007BFF;  /* Blue background */
  color: white;               /* White text color */
  border: none;               /* No border */
  padding: 10px 20px;         /* Top and bottom padding of 10px, left and right padding of 20px */
  font-size: 16px;            /* Font size of 16px */
  border-radius: 5px;         /* Rounded corners with a radius of 5px */
  cursor: pointer;            /* Pointer cursor on hover */
  outline: none;              /* Remove the outline to prevent the blue border on click in some browsers */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  font-size: 14px;
}

.rate-tap-btn:hover {
  background-color: #0056b3; /* Darker blue background on hover */
}

/* this stylizes all knobs */
.knob {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eeeeeefe;
}

.knob-value {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: normal;
  color: #817f7fe3;
  margin-top: 5px;
}

#volContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: auto;
  margin-left: 11%;
  margin-right: 12%;
}

#grooveVolContainer, #metronomeVolContainer, #tapVolContainer {
  display: inline-flex;       /* Establishes a grid container */
  place-items: center; /* Shortcut to align items both horizontally and vertically in the center */
  height: 70px;       /* Set a height to see the vertical alignment */
  padding: 10px;      /* Add padding to the container */
}

#grooveVolume {
  margin-left: 10px;
  margin-right: 10px;
}

#grooveText {
  margin-left: 30px;
}


/* /////// */
/* SLIDERS */
/* /////// */

.slider_text {
  color: #606060;
  margin-left: 20px;
  font-size: 14px;
}
.sliderz {
  margin-left: 13px;
}

/* the input styles are from https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/ */
.sliderz {
  -webkit-appearance: none;
  appearance: none;
  background: #606060;
  cursor: pointer;
  width: 15rem;
}
.sliderz::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -12px; /* Centers thumb on the track */
  background-color:  rgb(255, 0, 191);
  height: 0.8rem;
  width: 1.8rem;    
}


.likert-slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 10rem;
  height: 0.5rem;
  background: #606060;
}

.likert-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 1rem;
  height: 1rem;
  background-color:  rgb(255, 0, 191);
}


/* /////////////////////
HEADERS
///////////////////// */

.head {
  color: #606060;
  margin-left: 20px;
  font-family: "Bungee Shade", sans-serif;
  font-weight: 400;
  font-style: nor
}

#grooveHeader {
  color: #606060;
  margin-left: 20px;
}


.subhead {
  color: #606060;
  margin-left: 20px;
}

.header1 {
  color: #606060;
  font-style: italic;
}

.header2 {
  color: #606060;
  font-size: 13px;
}

#participantInfo {
  position: fixed; /* or absolute, depending on your layout */
  top: 0;
  right: 0;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  /* margin-left: 20px; */
}




/* /////// */
/* Components Styles */
.button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.readyButton {
  font-size: 14px;
}

/* Light Indicator Styles */
.light-base {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.01s;
  display: inline-block;
  vertical-align: middle;
}

#takeNumberDisplay, #takeNumber {
  font-size: 12px;
  color: #606060;

}

/* /////// */
/* Layout Styles */
/* /////// */

/* this is the rectangular box that contains the text and buttons */
.step {
 border: 1px solid #ccc;
 border-radius: 17px;
 margin: 20px auto;
 padding: 20px;
 overflow-y: auto;
 height: calc(100% - 80px); /* add this */
}

/* //////////////////////////////// */
/* /////// Container Styles /////// */
/* //////////////////////////////// */

/* .audioPlayerContainer {
  overflow-y: auto;
} */

/* #playButton {
  position: relative;
  margin-top: 100px;
} */

/* #audioProgressBar {
  width: 80%;
  height: 20px;
} */

.flexContainer {
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexContainer p {
  margin-left: 10px;
  overflow-y: auto;
}

.questContainer {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* space between child elements */
  padding: 20px;
  justify-content: space-between;
  position: relative;
}


.scrollableQuests {
  border: 1px solid #ccc;
  max-width: 600px; /* change as max-width */
  height: 500px;
  margin-bottom: 20px;
  padding: 15px;
  overflow-y: scroll;
 }


/* countdown container is for the text such as countdown and start1 */
.countdown-container {
  font-size: 15px;
  font-weight: initial;
  color: rgb(226, 43, 198);
  margin-bottom: 20px;
}

/* /////// */
/* Progress Bar Styles */
/* /////// */
#studyProgressBar {
  width: 50%;
  height: 20px;
}



/* //////////////////////////////// */
/* Fixed Progress Container Styles */
/* //////////////////////////////// */

/* this is the container in the bottom that houses the study progress bar*/
.fixed-progress-container {
  text-align: center;
  color: #000;
  background: #f0f0f0;
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -2px 5px #0000001a;
  height: 110px; /* add this */
 }

#progressText {
  margin-top: 10px;
}

/* Controls Styles */
#tapControls {
  display: none;
}

/* Next Button Styles */
#nextButton {
  margin-top: 10px;
}

/* State Styles */

/* Light States */
.light-on {
  background-color: rgb(255, 0, 191);
}

.light-off {
  background-color: rgba(128, 128, 128, 0.084);
}

/* Recording Indicator Styles */
.recording {
  color: rgb(226, 43, 198);
}



/* /////// */
/* CONSENT */
/* /////// */

#consent {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 80px);
  display: flex;
  overflow: auto;
 }

 consentCheckbox label { /* add a class to label and use that class here */
  font-size: 12px;
 }
 


#consentFormContainer {
  box-sizing: border-box;
  border: 1px solid #ccc;
  max-width: 600px;
  /* max-height: 80vh; */
  margin: auto;
  padding: 10px;
  /* overflow-y: auto; */
 }


.scrollableTextArea {
  border: 1px solid #ccc;
  height: 300px; /* Adjust based on your content */
  overflow-y: scroll;
  margin-bottom: 20px;
  padding: 15px;
}

#consentFormText {
  font-size: small;
}

#proceedButton {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.5; /* Initially disabled look */
}

#proceedButton:enabled {
  opacity: 1; /* Fully opaque when enabled */
}





/* /////// */
/* Hyperlink Styles */
a {
  color: #8be9fd; /* Normal link color */
}
a:hover {
  color: #e22bc6; /* Hover color */
}
a:visited {
  color: #781769; /* Visited link color */
}




#instructionText {
  color: #585757e3;
  background-color: #f5f5f5;
  font-family: var(--main-font);
  font-stretch: extra-expanded;
  height: 40vh;
}

#reminderText {
  color: #585757e3;
  background-color: #f5f5f5;
  font-family: var(--main-font);
  font-size: 13px; 
  font-stretch: extra-expanded;
}


#instruction {
  /* margin-left:  25%;
  margin-right: 25%; */
  max-width: 600px;
  max-height: 600px;
} 

#instructionText {
  border-color: transparent;
}

#instructionVideo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loopToggle {
  width: 10px;
  height: 10px;
}

.loopToggleContainer {
  color: #585757e3;
  font-family: var(--main-font);
  font-size: 13px; 
}


/* /////// */
/* /////// */
/* /////// */


#pre_questionnaire, #post_questionnaire {
  font-family: var(--main-font);
  width: 100%;
  height: 60vh; 
  overflow: auto; 

}





/* INLINE EXTRAS */

.highlight {
  color: #f67b65; 
}

.highlight2 {
  color: #06b37f; 
}

.highlight3 {
  color: #FF00BF; 
}

.larger-text {
  font-size: 18px; 
}

.largerer-text {
  font-size: 20px; 
}

.smaller-text {
  font-size: 13px; 
}

.normal-text {
  font-size: 16px; 
}

.funky-text {
  font-family: "Bungee Shade", sans-serif;
  font-size: 27px; 
}



/* /////// */
/* /////// */
/* /////// */

.mobile-warning {
  display:none;
}

@media screen and (width <= 768px) {
  .mobile-warning {
      display:block;
  }
}




/* Popup question */
/* Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}









/* /////// */
/* FONTS */
/* /////// */

.montserrat-subrayada-regular {
  font-family: "Montserrat Subrayada", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-subrayada-bold {
  font-family: "Montserrat Subrayada", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bungee-inline-regular {
  font-family: "Bungee Inline", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.bungee-spice-regular {
  font-family: "Bungee Spice", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bungee-shade-regular {
  font-family: "Bungee Shade", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.righteous-regular {
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}




/* ??? */
.scrollableQuests {
  width: 100%;
  height: 100vh; 
  overflow: auto; 
}
