:root {
  --main-font: "Noto Sans", sans-serif;
}

html, body {
  font-family: var(--main-font);
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-stretch: 150%;
}

main {
  height: calc(100% - 190px);
  padding-top: 55px;
  overflow: hidden;
}

#introContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#introduction, #information, #pre_questionnaire {
  max-width: 650px;
}

#top {
  margin-left: 13%;
  margin-right: 13%;
}

#stepsContainer {
  max-height: 100%;
  margin-left: 13%;
  margin-right: 13%;
  padding: 10px;
  overflow-y: auto;
}

.interfaceContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.box {
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 0 10px;
  display: flex;
  box-shadow: 0 4px 8px #0000001a;
}

.playButton {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  cursor: not-allowed;
  background-color: #ced4da;
}

.rate-tap-btn {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px 20px;
  font-size: 14px;
  transition: background-color .3s;
}

.rate-tap-btn:hover {
  background-color: #0056b3;
}

.knob {
  background-color: #eeeeeefe;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.knob-value {
  font-family: var(--main-font);
  color: #817f7fe3;
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
}

#volContainer {
  justify-content: space-between;
  align-items: center;
  margin-left: 11%;
  margin-right: 12%;
  padding: auto;
  display: flex;
}

#grooveVolContainer, #metronomeVolContainer, #tapVolContainer {
  place-items: center;
  height: 70px;
  padding: 10px;
  display: inline-flex;
}

#grooveVolume {
  margin-left: 10px;
  margin-right: 10px;
}

#grooveText {
  margin-left: 30px;
}

.slider_text {
  color: #606060;
  margin-left: 20px;
  font-size: 14px;
}

.sliderz {
  appearance: none;
  cursor: pointer;
  background: #606060;
  width: 15rem;
  margin-left: 13px;
}

.sliderz::-webkit-slider-thumb {
  appearance: none;
  background-color: #ff00bf;
  width: 1.8rem;
  height: .8rem;
  margin-top: -12px;
}

.likert-slider {
  appearance: none;
  cursor: pointer;
  background: #606060;
  width: 10rem;
  height: .5rem;
}

.likert-slider::-webkit-slider-thumb {
  appearance: none;
  background-color: #ff00bf;
  width: 1rem;
  height: 1rem;
}

.head {
  color: #606060;
  font-family: Bungee Shade, sans-serif;
  font-weight: 400;
  font-style: nor;
  margin-left: 20px;
}

#grooveHeader, .subhead {
  color: #606060;
  margin-left: 20px;
}

.header1 {
  color: #606060;
  font-style: italic;
}

.header2 {
  color: #606060;
  font-size: 13px;
}

#participantInfo {
  color: #fff;
  background-color: #007bff;
  border-bottom-left-radius: 5px;
  padding: 10px;
  font-size: 14px;
  position: fixed;
  top: 0;
  right: 0;
}

.button {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
}

.readyButton {
  font-size: 14px;
}

.light-base {
  vertical-align: middle;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: background-color 10ms;
  display: inline-block;
}

#takeNumberDisplay, #takeNumber {
  color: #606060;
  font-size: 12px;
}

.step {
  border: 1px solid #ccc;
  border-radius: 17px;
  height: calc(100% - 80px);
  margin: 20px auto;
  padding: 20px;
  overflow-y: auto;
}

.flexContainer {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.flexContainer p {
  margin-left: 10px;
  overflow-y: auto;
}

.questContainer {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: inline-block;
  position: relative;
}

.scrollableQuests {
  border: 1px solid #ccc;
  max-width: 600px;
  height: 500px;
  margin-bottom: 20px;
  padding: 15px;
  overflow-y: scroll;
}

.countdown-container {
  font-size: 15px;
  font-weight: initial;
  color: #e22bc6;
  margin-bottom: 20px;
}

#studyProgressBar {
  width: 50%;
  height: 20px;
}

.fixed-progress-container {
  text-align: center;
  color: #000;
  background: #f0f0f0;
  width: 100%;
  height: 110px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -2px 5px #0000001a;
}

#progressText {
  margin-top: 10px;
}

#tapControls {
  display: none;
}

#nextButton {
  margin-top: 10px;
}

.light-on {
  background-color: #ff00bf;
}

.light-off {
  background-color: #80808015;
}

.recording {
  color: #e22bc6;
}

#consent {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 80px);
  display: flex;
  overflow: auto;
}

consentCheckbox label {
  font-size: 12px;
}

#consentFormContainer {
  box-sizing: border-box;
  border: 1px solid #ccc;
  max-width: 600px;
  margin: auto;
  padding: 10px;
}

.scrollableTextArea {
  border: 1px solid #ccc;
  height: 300px;
  margin-bottom: 20px;
  padding: 15px;
  overflow-y: scroll;
}

#consentFormText {
  font-size: small;
}

#proceedButton {
  color: #fff;
  text-align: center;
  cursor: pointer;
  opacity: .5;
  background-color: #4caf50;
  border: none;
  padding: 15px 32px;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}

#proceedButton:enabled {
  opacity: 1;
}

a {
  color: #8be9fd;
}

a:hover {
  color: #e22bc6;
}

a:visited {
  color: #781769;
}

#instructionText {
  color: #585757e3;
  font-family: var(--main-font);
  background-color: #f5f5f5;
  height: 40vh;
  font-stretch: extra-expanded;
}

#reminderText {
  color: #585757e3;
  font-family: var(--main-font);
  background-color: #f5f5f5;
  font-size: 13px;
  font-stretch: extra-expanded;
}

#instruction {
  max-width: 600px;
  max-height: 600px;
}

#instructionText {
  border-color: #0000;
}

#instructionVideo {
  justify-content: center;
  align-items: center;
  display: flex;
}

.loopToggle {
  width: 10px;
  height: 10px;
}

.loopToggleContainer {
  color: #585757e3;
  font-family: var(--main-font);
  font-size: 13px;
}

#pre_questionnaire, #post_questionnaire {
  font-family: var(--main-font);
  width: 100%;
  height: 60vh;
  overflow: auto;
}

.highlight {
  color: #f67b65;
}

.highlight2 {
  color: #06b37f;
}

.highlight3 {
  color: #ff00bf;
}

.larger-text {
  font-size: 18px;
}

.largerer-text {
  font-size: 20px;
}

.smaller-text {
  font-size: 13px;
}

.normal-text {
  font-size: 16px;
}

.funky-text {
  font-family: Bungee Shade, sans-serif;
  font-size: 27px;
}

.mobile-warning {
  display: none;
}

@media screen and (width <= 768px) {
  .mobile-warning {
    display: block;
  }
}

.modal {
  z-index: 1;
  background-color: #0006;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 80%;
  margin: 15% auto;
  padding: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.montserrat-subrayada-regular {
  font-family: Montserrat Subrayada, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.montserrat-subrayada-bold {
  font-family: Montserrat Subrayada, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.bungee-inline-regular {
  font-family: Bungee Inline, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.bungee-spice-regular {
  font-family: Bungee Spice, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.bungee-shade-regular {
  font-family: Bungee Shade, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.righteous-regular {
  font-family: Righteous, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.poppins-regular {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.scrollableQuests {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
/*# sourceMappingURL=index.c564b81c.css.map */
